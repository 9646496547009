<template>
  <div>
    <transition name="fade">
      <div v-if="errors.length > 0" class="banner orange error">
        <span uk-icon="icon: warning"></span>
        <p v-for="error in errors" :key="error">{{ $t('errors.' + error) }}</p>
      </div>
    </transition>
    <transition name="fade">
      <div v-if="successes.length > 0">
        <p v-for="success in successes" :key="success">{{ $t('errors.' + success) }}</p>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'AlertsDisplay',
  props: {
    errors: {
      type: Array,
      default: () => {
        return []
      }
    },
    successes: {
      type: Array,
      default: () => {
        return []
      }
    }
  }
}
</script>

<style lang="less" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
